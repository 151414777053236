// Powerful tools to create a website similar to your design

import React from "react";
import "./data-section.scss";
import {StaticImage} from "gatsby-plugin-image";
import {WaitlistButton} from "./waitlist-button";


export const DataSection = () => {
  return <section className="data-section">
    <h2>Powerful data manipulation</h2>

    <div className="data-section-columns">
      <div className="left">
        <p>Use data grids with an interactive schema to create your website data. Go beyond with creating a personalized
          data views, like Kanban, Cards or Knowledgebase to drive your business to the next level.</p>
        <WaitlistButton/>
      </div>

      <div className="right">
        <StaticImage src="../images/data-table.png" alt="Collaboration"/>
      </div>
    </div>

  </section>;
}
