import React from "react";
import "./dark-section.scss";
import {StaticImage} from "gatsby-plugin-image";

export const DarkSection = () => {
  return <section className="dark-section">
    <div className="dark-section-content">
      <div className="left">
        <h2>Empower your workflow with Skivvr. Create a website 4x times faster than with code.</h2>
      </div>
      <div className="right">
        <StaticImage src="../images/app-1.png" alt="Skivvr application"/>
      </div>
    </div>
  </section>;
}
