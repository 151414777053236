import React from "react";
import "./tools-section.scss";
import {FlyUp} from "./fly-up";
import {StaticImage} from "gatsby-plugin-image";

export const ToolsSection = () => {
  return <section className="tools-section">
    <h2>Familiar tools to create a website similar to your design</h2>

    <div className="tools">
      <FlyUp className="tool-components">
        <StaticImage src="../images/tools/components.png" width={288} alt="Components"/>
      </FlyUp>
      <FlyUp className="tool-shadow">
        <StaticImage src="../images/tools/shadow.png" width={240} alt="Shadow tool"/>
      </FlyUp>
      <FlyUp className="tool-background">
        <StaticImage src="../images/tools/background.png" width={240} alt="Background tool"/>
      </FlyUp>
      <FlyUp className="tool-transform">
        <StaticImage src="../images/tools/transform.png" width={240} alt="Transform tool"/>
      </FlyUp>
      <FlyUp className="tool-heading">
        <StaticImage src="../images/tools/heading.png" width={240} alt="Attributes tool"/>
      </FlyUp>
      <FlyUp className="tool-typography">
        <StaticImage src="../images/tools/typography.png" width={240} alt="Typography tool"/>
      </FlyUp>
    </div>
  </section>;
}
