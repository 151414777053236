import React from "react";

import "./pricing-column.scss";

type IProps = {
  children: React.ReactChild | React.ReactChild[];
  className?: string;
}

export const PricingColumn = ({children, className}: IProps) => {
  return <div className={`pricing-column ${className || ''}`}>
    {children}
  </div>;
}
