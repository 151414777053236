import React, {useCallback, useEffect, useRef, useState} from "react";
import "./fly-up.scss";
import {isBrowser} from "../is-browser.helper";

type IProps = {
  children: React.ReactChild;
  className?: string;
}

export const FlyUp = ({children, className}: IProps) => {
  const [visible, setVisible] = useState(false);
  const element = useRef<HTMLDivElement | null>(null);

  const windowHeight = useRef(isBrowser() ? window.innerHeight : 1024);

  useEffect(() => {
    const positionFromTop = element?.current?.getBoundingClientRect().top;
    if (positionFromTop === undefined) {
      return;
    }

    if (positionFromTop - windowHeight.current <= 0) {
      setVisible(true);
    }
  }, [element.current]);

  const changeVisibility = useCallback((e: MouseEvent) => {
    const positionFromTop = element?.current?.getBoundingClientRect().top;
    if (positionFromTop === undefined) {
      return;
    }

    if (positionFromTop - windowHeight.current <= 0) {
      setVisible(true);
    }
  }, []);

  const resize = useCallback(() => {
    windowHeight.current = window.innerHeight;
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', changeVisibility);
    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('scroll', changeVisibility);
      window.removeEventListener('resize', resize);
    }
  }, []);

  return <div ref={element} className={`fly-up ${className || ''} ${visible ? 'visible' : ''}`}>
    {children}
  </div>;
}
