import React from "react";
import "./plan-section.scss";
import {StaticImage} from "gatsby-plugin-image";

export const PlanSection = () => {
  return <section className="plan-section">
    <div className="plan-section-content">
      <h2>Our plan</h2>
      <p>
        We plan to gradually expand Skivvr. Our first step is to create a state-of-the-art no-code website designer.
        Then we are going to add a data management solution. And, on top of that, we add integration between designer
        and tables, giving you the full power of creating your perfect customer- and business-faced apps, everything
        without any code from your side.
      </p>
      <div className="plan-image">
        <StaticImage src="../images/plan.png" alt="Plan"/>
      </div>
    </div>
  </section>;
}
