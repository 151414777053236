import * as React from "react";
import "./hero.scss";
// @ts-ignore
import LogoBig from "../images/logo-big.inline.svg"
import {SignupButton} from "./signup-button";
import {StaticImage} from "gatsby-plugin-image";
import {WaitlistButton} from "./waitlist-button";


export const Hero = () => {
  return <section className="hero">
    <div className="hero-title">
      <span>Meet</span><LogoBig/>
    </div>
    <h1>A realtime collaborative no-code web application creation platform.</h1>
    {/*<SignupButton/>*/}

    <WaitlistButton/>

    <data id="mj-w-res-data" data-token="ae1cf357456d8bea4f430e86a9a68d5d" className="mj-w-data" data-apikey="7rG3"
          data-w-id="LBr" data-lang="en_US" data-base="https://app.mailjet.com" data-width="640" data-height="434"
          data-statics="statics"></data>


    <StaticImage placeholder="none" src="../images/preview.png" alt="Skivvr preview"/>
  </section>;
}