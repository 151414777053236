// Powerful tools to create a website similar to your design

import React from "react";
import "./benefits-section.scss";
import {StaticImage} from "gatsby-plugin-image";
import {WaitlistButton} from "./waitlist-button";


export const BenefitsSection = () => {
  return <section className="benefits-section">
    <h2>Benefits</h2>

    <div className="left">
      <ul>
        <li>Clean and semantic code</li>
        <li>Work alone or with your team</li>
        <li>Real-time collaboration</li>
        <li>Smart controls</li>
        <li>Static website generator for free</li>
        <li>Build responsive websites with drag and drop</li>
        <li>Powerful components system with inheritance and overloads</li>
        <li>All Google fonts with previews</li>
        <li>Powerful data and logic solution in a near future</li>
      </ul>
      <WaitlistButton color="sea"/>
    </div>

    <div className="right">
      <StaticImage src="../images/app-2.png" width={695} alt="Skivvr application"/>
    </div>
  </section>;
}
