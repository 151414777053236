// Powerful tools to create a website similar to your design

import React from "react";
import "./collaboration-section.scss";
// @ts-ignore
import Collab from "../images/collaborate-animation.inline.svg";
import {StaticImage} from "gatsby-plugin-image";
import {WaitlistButton} from "./waitlist-button";


export const CollaborationSection = () => {
  return <section className="collaboration-section">
    <h2>True real-time collaboration</h2>

    <div className="collaboration-columns">
      <div className="left">
        <div className="collaboration-image">
          <StaticImage src="../images/collaboration.png" alt="Collaboration"/>
        </div>
        <Collab/>
      </div>

      <div className="right">
        <p>Work together on the same project. Collaborate smartly: design, create and fine&#8209;tune content, seeing
          all
          changes in real&#8209;time. Handoff to your clients simply by adding them as collaborators.</p>
        <WaitlistButton/>
      </div>
    </div>


  </section>;
}
