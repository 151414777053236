import * as React from "react"
import {Layout} from "../components/layout";
import {Header} from "../components/header";
import {Hero} from "../components/hero";
import "./index.scss";
import {DarkSection} from "../components/dark-section";
import {ToolsSection} from "../components/tools-section";
import {CollaborationSection} from "../components/collaboration-section";
import {PricingSection} from "../components/pricing-section";
import {DataSection} from "../components/data-section";
import {PlanSection} from "../components/plan-section";
import {BenefitsSection} from "../components/benefits-section";

const IndexPage = () => {
  return (
    <Layout>
      <Header/>

      <div className="parallax-background">
        <Hero/>
      </div>

      <DarkSection/>
      <ToolsSection/>

      <div className="parallax-background" style={{height: 400}}>
      </div>

      <DataSection/>

      <CollaborationSection/>
      <div className="parallax-background">
        <PricingSection/>
      </div>
      <PlanSection/>
      <BenefitsSection/>
    </Layout>
  )
}

export default IndexPage
