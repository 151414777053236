import React from "react";
import "./pricing-section.scss";
import {PricingColumn} from "./pricing-column";
import {WaitlistButton} from "./waitlist-button";

export const PricingSection = () => {
  return <section className="pricing-section">
    <h2><span>Free</span> while in Beta</h2>
    <p>
      Create a website, host it, and connect a custom domain.
      <br/>
      After Beta, pay only for using a custom domain and having more than two collaborators.
    </p>
    <div className="pricing-columns">
      <PricingColumn className="pricing-column-sea">
        <div className="pricing-title">
          Beta
        </div>
        <div className="pricing-price">
          $0
        </div>
        <div className="pricing-subtitle">
          Free
        </div>
        <ul className="pricing-items">
          <li className="pricing-item">
            yoursite.skivvr.com domain
          </li>
          <li className="pricing-item">
            Use custom domain
          </li>
          <li className="pricing-item">
            Unlimited pages
          </li>
          <li className="pricing-item">
            Export static website
          </li>
          <li className="pricing-item">
            Email support
          </li>
          <li className="pricing-item">
            SSL certificate
          </li>
          <li className="pricing-item">
            Skivvr badge
          </li>
        </ul>
      </PricingColumn>
      <PricingColumn className="pricing-column-violin">
        <div className="pricing-title">
          Premium
        </div>
        <div className="pricing-price">
          TBA
        </div>
        <div className="pricing-subtitle">
          &nbsp;
        </div>
        <ul className="pricing-items">
          <li className="pricing-item">
            Use custom domain
          </li>
          <li className="pricing-item">
            Add more than two collaborators
            (+$2/month for third and above)
          </li>
          <li className="pricing-item">
            No Skivvr badge
          </li>
        </ul>
      </PricingColumn>
    </div>
    <WaitlistButton/>
  </section>;
}
